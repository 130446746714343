<template lang="pug">
v-dialog(:value="true" max-width="700px" @input="$emit('close')")
  v-card
    v-card-title.d-flex.justify-space-between
      span Просмотр аккаунта
      AccountMenu(
        :item="item"
        :menuProps="{offsetY:true}"
      )
    DefinitionList(:value="itemData")

    v-card-text
      v-row(align-end)
        v-spacer
        v-col(cols="12" sm="6")
          v-btn(
            @click="$emit('close')"
            outlined
            block
          ) Закрыть
</template>

<script>
import Spinner from '@/components/Spinner';
import DefinitionList from '@/components/DefinitionList';
import AccountMenu from '@/views/accounts/components/AccountMenu';

import { mapActions, mapGetters } from 'vuex';
import { convertAccount } from '@/utils/convert/account';

export default {
  components: {
    Spinner,
    AccountMenu,
    DefinitionList,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters('ACCOUNTS', ['listItem', 'role', 'itemBindings']),
    ...mapGetters(['organizations']),
    item() {
      return this.listItem(this.id);
    },
    itemData() {
      const item = convertAccount(this.item);
      const orgBindinngs = this.itemBindings(item.id).orgs.map(id => {
        return this.organizations.find(item => item.id === id)?.name;
      });
      return [
        { label: 'Id', value: item.id },
        { label: 'Email', value: item.email },
        { label: 'Тип аккаунта', value: item.type },
        { label: 'Статус аккаунта', value: item.isEnabled },
        { label: 'Роль', value: this.role(item.roleId, 'name') },
        { label: 'ФИО', value: item.name },
        { label: 'Организация', value: item.organization },
        { label: 'Позиция', value: item.position },
        {
          label: 'Привязки аккаунта к организациям',
          value: orgBindinngs.join(', ') || '-',
        },
      ];
    },
  },

  async created() {
    this.isLoading = true;
    await this.fetchItem(this.id);
    this.isLoading = false;
  },

  methods: {
    ...mapActions('ACCOUNTS', ['fetchItem']),
  },
};
</script>
